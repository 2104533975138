.Tittlesection1PC {
  font-size: 35px;
  color: #fff;
  text-align: left;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px 50px;
  padding-top: 50px;
  animation: fadeIn 2s;
}

.filterSection1PC {
  position: absolute;
  background-color: #0000006b;
  width: 100%;
  height: 100%;
}

/* --------------------------- SECTION 2 ---------------------------------------- */
.Row1Section2PaqCom {
  margin: 3% 0px !important;
  padding: 10px 5%;
  width: 100%;
  height: 50%;
  border-radius: 10px;
}

.TittleSection2PaqCom {
  padding-left: 20px;
  padding-top: 3%;
  padding-bottom: 0px;
  margin: 0px 0px !important;
  color: #fff;
  z-index: 2;
  position: relative;
  width: 100%;
  height: auto;
}

.TittleSection2PaqCom h2 {
  font-weight: 400;
  font-size: 35px;
  color: white;
  text-align: left;
  padding: 10px 30px !important;
  margin: 0px 0px !important;
  text-align: left;
}

.ColSection2PaqCom {
  padding: 0px 0px;
  margin: 0px 0px;
  text-align: center;
  /* width: 100%; */
  height: 100%;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  position: relative;
}

.ImgBgColPaqCom {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0px 0px;
  padding: 0px 0px;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
  position: relative;
}


.CapaTextoSection2PaqCom {
  background-color: #294963c3;
  z-index: 3;
  position: absolute;
  color: #fff;
  padding: 0px 0px;
  margin: 0px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 70%;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #000000;
}

.CapaTextoSection2PaqCom h3 {
  font-weight: 400;
  font-size: 25px;
  color: white;
  display:flex;
  flex-direction: column;
  margin: 0px 0px;
  border-radius: 8px;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.filterSection2PaqCom{
  position: absolute;
  background-color: #00000090;
  width: 100%;
  height: 100%;
  z-index: 1;
}


@media screen and (max-width: 768px) {
  .Row1Section2PaqCom {
    margin: 0px 0px !important;
    padding: 0px 0px !important;
    width: 100%;
    height: 17%;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
  }
  
  .TittleSection2PaqCom {
    padding: 0px 20px !important;
    margin: 0px 0px !important;
    color: #fff;
    width: 100%;
    height: auto;
    /* background-color: red; */
  }
  
  .TittleSection2PaqCom h2 {
    font-weight: 400;
    font-size: 35px;
    color: white;
    text-align: left;
    padding: 20px 20px !important;
    margin: 0px 0px !important;
    text-align: left;
  }
  
  .ColSection2PaqCom {
    padding: 0px 0px !important;
    margin: 10px 0px !important;
    text-align: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    
  }
  
  .ImgBgColPaqCom {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0px 0px;
    padding: 0px 0px;
    border-radius: 15px;
    position: relative;
  }
  
  
  .CapaTextoSection2PaqCom {
    background-color: #294963c4;
    z-index: 3;
    color: #fff;
    padding: 0px 0px;
    margin: 0px 0px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    height: 90%;
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px #000000;
    position: absolute;
  }
  
  .CapaTextoSection2PaqCom h3 {
    font-weight: 400;
    font-size: 25px;
    color: white;
    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0px;
    width: 100%;
    border-radius: 8px;
  }
}