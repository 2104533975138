.ContainerContacInfo {
  padding: 0px 0px;
  overflow: hidden;
  position: relative;
  height: 100%;
}

/* .filter1ContactInfo, */
.Col2section1InfoContact {
  height: 100%;
  position: relative;
  padding: 0px 0px;
  margin: 0px 0px;
  overflow: hidden;
}

.RowNosotros {
  width: 100%;
  height: 100%;
  padding: 0px 10px !important;
  margin: 0px 0px !important;
  overflow: hidden;
}

.filter1ContactInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #13161ac5;
}

.Col1section1InfoContact {
  margin: 0px 0px;
  padding: 0px 0px !important;
  height: 100%;
  object-fit: cover;
  position: relative;
}

/*------------- ESPACIADO PARA AMBAS COLUMNAS */
.espaciadosection1InfoContact {
  padding: 5% 50px;
  border: none;
  background-color: #2c4763b6;
}

.espaciado2section1InfoContact {
  border: none;
  border-radius: 15px;
}

.espaciado2section1InfoContact,
.espaciadosection1InfoContact {
  margin: 20px 0px;
  border: none;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  

}

/*-------------- CONTENIDO ESTILOS  */
.tittlesection1InfoContact {
  font-size: 20px;
  color: #fff;
  position: relative;
  
  text-align: center;
  display: flex;
  flex-direction: column;

}


.subtittlesection1InfoContact {
  font-size: 15px;
  color: #fff;
  position: relative;
  text-align: center;
}

.Parrafosection3Home {
  font-size: 18px;
  color: #fff;
  position: relative;
  text-align: left;
  justify-content: center;
}


/* ------------Columna 2  */
.Col2section1InfoContact {
  margin: 0px 0px !important;
  padding: 0px 0px !important;
  height: 100%;
  position: relative;
}


/* ----------- ICONOS  */
.Social-media1,
.Social-media2 {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 10% !important;
}

.Social-media1 {
  text-align: right;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.LinkedinIcon {
  width: 50px;
  height:50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Social-media2 {
  text-align: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.InstagramIcon {
  width: 50px;
  height:50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-height: 1024px) {
  .filter1ContactInfo {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .Col2section1InfoContact {
    margin: 0px 0px !important;
    padding: 0px 10px !important;
    height: 100%;
    position: relative;
    border-radius: 15px;
  }
}

@media screen and (max-width: 768px) {
  .filter1ContactInfo {
    width: 100%;
  }

  .RowNosotros {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    overflow: hidden;
  }

  .subtittlesection1InfoContact {
    display: none;
  }

  .Col1section1InfoContact {
    margin: 0px 0px !important;
    padding: 0px 0px !important;
    height: 100% !important;
    position: relative;
    text-align: center;
  }

  .espaciadosection1InfoContact {
    background-color: #2c4763b6;
    border: none;
    border-radius: 15px;
  }

  .tittlesection1InfoContact {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
  }

  .Parrafosection3Home {
    margin: 0px 0px !important;
    padding: 10px 0px !important;
    font-size: 15px;
    color: #fff;
    position: relative;
    text-align: center;
    padding: 0px 0px;
    justify-content: center;
  }

  .Col2section1InfoContact {
    margin: 0px 0px;
    padding: 0px 0px;
    width: 100%;
    position: relative;
  }

  .espaciado2section1InfoContact {
    margin: 0% 0%;
    padding: 0px 0px;
    justify-content: center;
    border: none;
    border-radius: 10px;
    text-align: center;
    width: 100%;
  }

  .ColIconNosotros {
    margin: 0px 0px !important;
    padding: 0px 0px !important;
  }

  .Social-media1,
  .Social-media2 {
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px 0px !important;

  }

  .Social-media1 {
    width: 100%;

  }

  .LinkedinIcon {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0px 0px !important;
    padding: 0px 0px !important;
  }

  .Social-media2 {
    width: 100%;
  }

  .InstagramIcon {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}