/* FONDO DE LA SECCIÓN 1 */
.filter1 {
  position: absolute;
  background-color: #1d3345b5;
  width: 100%;
  height: 100%;
}

.ContainerSeccion1Home {
  height: 100%;
  width: 100%;
  text-align: left;
  margin: 0px 0px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-top: 0%;
  padding-left: 10% !important;
}

.tittle1seccion1Home {
  font-size: 35px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 3px;
  width: 100%;
  padding: 20px 0px;
}

.ColPrendaHome {
  padding: 0px 0px;
  margin: 0px 0px;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.ColPrendaHome2 {
  padding: 0px 0px;
  margin: 0px 0px;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 70% !important;
}

.Parrafoseccion1Home {
  display: flex;
  flex-direction: column;
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  width: 90%;
  margin: 0px 0px;
  padding: 20px 0px;
  text-align: left;
  letter-spacing: 2px;

}



/*-------------------------------- SECCIÓN 2 ---------------------------------*/

.OverFlowNone {
  overflow: hidden;
}

.Col1Divtextil,
.Col2Divtextil {
  padding: 0px 0px;
  margin: 0px 0px;
  text-align: center;
  width: 100%;
  height: 100%;
}

.Col1Divtextil {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Col2Divtextil{
  padding: 0px 0px !important;
  margin: 0px 0px;
  text-align: center;
  width: 100%;
}

.filter2 {
  position: absolute;
  background-color: #2829297e;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.tittle1section2Home {
  font-size: 45px;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  letter-spacing: 3px;
  padding: 50% 0px;
}

.bgPaqCom {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  padding: 0px 0px;
  margin: 0px 0px;
}


/*-------------------------- SECCIÓN 3 ----------------------------*/

.tittle1section3Home {
  position: relative;
  font-size: 35px;
  font-weight: 500;
  color: #f5f5f5;
  text-align: left;
  width: 100%;
  height: auto;
  padding: 40px 20px;
}

.subtitle1section3Home {
  font-size: 25px;
  color: #fff;
  display: flex;
  text-align: left;
  width: 80%;
  padding: 10px 30px;
  position: relative;
}

.ColCardsInfoHome {
  margin: 0px 0px;

  height: 100%;
}

.RowCardsInfoHome {
  margin: 0px 0px;
  padding: 20px 0px;
  width: 100%;
  height: auto;
}

.filter3 {
  background-color: #223549c4;
  width: 100%;
  height: 100%;
  position: absolute; 
}



/* --------------------- SSECCIÓN 4------------------------------- */

@media screen and (max-width: 300px) {

  .filter1 {
    position: absolute;
    background-color: #213545bc;
    width: 100%;
    height: 100%;
  }

  .ContainerSeccion1Home {
    top: 0px;
    height: 100%;
    width: 100%;
    text-align: left;
    padding: 0px 0px;
  }

  .tittle1seccion1Home {
    font-size: 25px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 4px;
    width: 80%;
    padding: 0px 0px;
    margin: 5rem 0px;

  }

  .Parrafoseccion1Home {
    font-size: 25px;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: 400;
    text-align: start;
    letter-spacing: 0px;
    margin-top: 2rem;
    width: 80%;
  }

  .ColPrendaHome {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
  }

  /* SESION 2 ---------------------- */
  /* FONDOS  */
}
@media screen and (max-width: 768px) {

  /* Sesión 1 */

  .filter1 {
    position: absolute !important;
    background-color: #152838b7;
    width: 100%;
    height: 100%;
  }

  .ContainerSeccion1Home {
    height: 100%;
    width: 100%;
    text-align: left;
    margin: 0px 0px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-top: 0px 0px;
    padding-left: 10%;
    overflow: hidden;
  }

  .tittle1seccion1Home {
    font-size: 45px;
    color: #fff;
    letter-spacing: 3px;
    width: 100%;
    padding: 0px 0px;
    margin: 0px 0px;
  }

  .ColPrendaHome {
    padding: 0px 0px;
    margin: 0px 0px;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .ColPrendaHome2 {
    padding: 0px 0px;
    margin: 0px 0px;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100% !important;
  }

  .Parrafoseccion1Home {
    display: flex;
    flex-direction: column;
    font-size: 25px;
    color: #fff;
    font-weight: 500;
    width: 100%;
    margin: 0px 0px;
    padding: 0px 0px;
    text-align: left;
    letter-spacing: 2px;

  }

  /* SESION 2 ---------------------- */
  /* FONDOS  */

  .OverFlowNone {
    overflow: hidden;
  }
  
  .Col1Divtextil,
  .Col2Divtextil {
    padding: 0px 0px;
    margin: 0px 0px;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  
  .filter2 {
    position: absolute;
    background-color: #2828297e;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .tittle1section2Home {
    font-size: 35px;
    color: #fff;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 5% 0px;
  }


  /* SECCION 3 ------------------------- */


  .tittle1section3Home {
    font-size: 25px;
    font-weight: 500;
    color: #f5f5f5;
    text-align: center;
    width: 100%;
    padding: 10px 0px;
  }

  .subtitle1section3Home {
    font-size: 20px;
    color: #fff;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0px 0px;
  }

  .ColCardsInfoHome {
    margin: 0px 0px;
    padding: 0px 0px;
    width: 100%;
    height:16% ;
  }

  .filter3 {
    position: absolute;
    background-color: #2c4763d0;
    width: 100%;
    height: 100%;
    margin: 0px 0px;
  }
}