.media-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}


.background-image {
  width: 100%;
  /* Video ocupará todo el ancho */
  height: 100vh;
  /* Video ocupará todo el alto */
  object-fit: cover;
  /* Ajustar el video al tamaño del contenedor */
  margin: 0px 0px;
  padding: 0px 0px;
}

.overlay {

  position: absolute;
  top: 0;
  padding-top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.texto-central {

  margin-top: 100px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: justify;
  /* Centrar el texto */
}

.header-title {
  font-size: 35px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  width: 100%;
  padding: 0px 0px;
}

.header-subtitle {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.header-description {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
}


.buttonContainerHeader {
  justify-content: center;
  padding: 0px 0px;
  margin: 2rem 0px;
  width: 100%;
  height: 50%;
  text-align: center;
  margin-bottom: 5px;
}

.Col2ButtonsHeader {
  position: relative;
  width: 100%;
  text-align: left;

}

.Col1ButtonsHeader {
  width: 100%;
  margin: 0px 0px;
  text-align: right;

}

@media screen and (max-width: 768px) {

  .texto-central {
    top: 50% !important;
    width: 80%;
  }

  .background-image {
    width: 100%;
    object-fit: cover;
    margin: 0px 0px;
    padding: 0px 0px;
    height: 100vh;
  }

  .header-title {
    font-size: 35px !important;
  }

  .header-subtitle {
    display: none;
    font-size: 20px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .texto-central {
    top: 40%;
    left: 50%;
    margin-top: 0;
    transform: translate(-50%, -50%);
    text-align: center;
  }


  .header-description {
    font-size: 18px;
    font-weight: 200;
    text-align: center;
    letter-spacing: 0px;
  }
  .buttonContainerHeader {
    justify-content: center;
    padding: 0px 0px;
    margin: 2rem 0px;
    width: 100%;
    height: 50%;
    text-align: center;
  }
  .Col2ButtonsHeader {
    position: relative;
    width: 100%;
    text-align: center;
  }
  
  .Col1ButtonsHeader {
    width: 100%;
    margin: 0px 0px;
    text-align: center;
    margin: 2rem 0px;
  }
}
