.footer {
  position: relative;
  bottom: 0;
  padding: 0;
  color: white;
  background-color: #28455f;
  text-align: center;
  width: 100%;
  overflow: hidden;
  border-radius: 0px;
  animation: fadeIn 1s ease-in-out;
}
.footer,
.footer-logo {
  height: 100%;
}

.container-footer {
  overflow: hidden !important;
  width: 100%;
}

.footer-logo {
  width: 100%;
  padding: 0px 0px;
  margin: 0px 0px;
  animation: slideIn 1s ease-in-out;
  height: 5vh;
  /* height: 5vh; */
  width:70%;
  object-fit: cover;
}

.ColumnLogoFooter {
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  width: 5%;
  display: flex;
  justify-content: center;
}

.ColumnTextFooter {
  font-size: 10px;
  color: #fff;
  padding: 0px 0px;
  margin: 0px 0px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}



.footer .copyright,
.privacy-policy,
.terms-conditions {
  margin: 0px 0px;
  color: white;
  width: 100%;
  padding: 0px 0px;
  height: auto;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

  
.social-icon {
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  width: 40px;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: center;
  animation: slideIn 1s ease-in-out;


  }

@media screen and (max-width: 768px) {
  .footer {
    position: relative;
    bottom: 0;
    padding: 0;
    color: white;
    background-color: #28455f;
    text-align: center;
    overflow: hidden;
    border-radius: 0;
    animation: fadeIn 1s ease-in-out;
  }
  .footer,
  .container-footer {
    height: 100%;
    width: 100%;
  }
  .ColumnLogoFooter {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer-logo {
    position: relative;
    top: 0px;
    overflow: hidden;
    width: 50%;
    padding: 0px 0px;
    margin: 0px 0px;
    animation: slideIn 1s ease-in-out;
    height: auto;
  }
  .footer .copyright,
  .privacy-policy,
  .terms-conditions {
    margin: 0px 0px !important;
    padding: 0px 0px !important;
    color: white;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ColumnTextFooter {
    font-size: 10px;
    color: #fff;
    padding: 0px 0px;
    margin: 0px 0px;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 5vh;
    overflow-x: hidden !important;
  }

  
  .social-icon {
    padding: 0px 0px;
    margin: 0px 0px;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    height: auto;
    }
}
