/* General container styles */
.columnas-figure {
  position: relative;
  width: 100%;
  height: 400px; /* Ajustar según sea necesario */
  overflow: hidden;
  border-radius: 5px;
  margin: 2rem 0px;
  overflow: hidden;
}

/* Figure styles */
.figure {
  position: relative;
  width: 100%;
  height: 400px; /* Ajustar según sea necesario */
  overflow: hidden;
  border-radius: 5px;
}

/* Image styles */
.img-card-hover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.5s ease;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  background-attachment: fixed;
}

/* Text overlay styles */
.capa-texto-card-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  color: white;
  opacity: 1; /* Inicialmente invisible */
  transition: opacity 0.5s ease;
  border-radius: 5px;
  cursor: pointer;
}

.title-card-hover {
  font-size: 25px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  margin: 0 10px; /* Espaciado interno */
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 20%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.text-card-hover{
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0px;
  text-align: center;
  width: 80%;
  height: 30%;
}

/* .list-card-hover {
  font-size: 16px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  margin: 0px 0px;

  text-align: left;
  width: 100%;
  height: 100%;
  word-wrap: break-word;
} */

/* Hover effects */
.figure:hover .img-card-hover {
  transform: scale(1.1); /* Ampliar la imagen al pasar el mouse */
}

.figure:hover .capa-texto-card-hover {
  opacity: 0; /* Hacer visible la capa de texto */
}

@media screen and (max-width: 768px) {
  .columnas-figure {
    position: relative;
    width: 100%;
    height: 50vh; /* Ajustar según sea necesario */
    overflow: hidden;
    border-radius: 5px;
    margin: 1rem 0px;
  }

  /* Figure styles */
  .figure {
    position: relative;
    width: 100%;
    height: 400px; /* Ajustar según sea necesario */
    overflow: hidden;
    border-radius: 5px;
  }

  /* Image styles */
  .img-card-hover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.5s ease;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    background-attachment: fixed;
  }

  /* Text overlay styles */
  .capa-texto-card-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    color: white;
    opacity: 1; /* Inicialmente invisible */
    transition: opacity 0.5s ease;
    border-radius: 5px;
    cursor: pointer;
  }

  .title-card-hover {
    font-size: 25px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    margin: 0 20px; /* Espaciado interno */
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .list-card-hover {
    font-size: 16px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    margin: 0px 0px;

    text-align: left;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
  }

  /* Hover effects */
  .figure:hover .img-card-hover {
    transform: scale(1.1); /* Ampliar la imagen al pasar el mouse */
  }

  .figure:hover .capa-texto-card-hover {
    opacity: 0; /* Hacer visible la capa de texto */
  }
}
