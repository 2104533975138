.ContainerContac {
  padding: 0px 0px;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.bgimgsection1Contact {
  position: relative; /* PARA QUE EL OBJETO QUE APARECE ENCIMA DEL FONDO SE POSICIONE RELATIVO A ESTE */
  background-attachment: fixed; /* PARA QUE LA IMAGEN DE FONDO NO SE MUEVA */
  background-position: center; /* PARA QUE LA IMAGEN DE FONDO SE CENTRE */
  background-repeat: no-repeat; /* PARA QUE LA IMAGEN DE FONDO NO SE REPITA */
  background-size: cover; /*PARA QUE LA IMAGEN DE FONDO CUBRA EL 100% DEL ALTO DE LA PANTALLA */
  background-image: url("../../assets/media/PC/bgContacto.jpg");
  min-width: 100%; /* PARA QUE EL ALTO DEL FONDO SEA DEL 100% DEL ALTO DE LA PANTALLA */
  object-fit: cover;
}
.Col1section1Contact {
  margin: 0px;
  padding: 0px;
  object-fit: cover;
  position: relative;
  text-align: center;
}

.espaciadosection1Contact {
  margin: 10% 1px;
  padding: 0px 10%;
  background-color: #0b12184b;
  justify-content: left;
  text-align: left;
}

.tittlesection1Contact {
  font-size: 45px;
  color: #fff;
  font-family: "Times New Roman", Times, serif;
  position: relative;
  letter-spacing: 4px;
  text-align: center;
  top: 10%;
}

.subtittlesection1Contact {
  font-size: 25px;
  color: #fff;
  font-family: "Times New Roman", Times, serif;
  position: relative;
  text-align: left;
}

.Parrafosection1Contact {
  font-size: 20px;
  color: #fff;
  font-family: "Times New Roman", Times, serif;
  position: relative;
  text-align: left;
}

.filter2ContactInfo {
  position: absolute;
  background-color: #28292980;
  width: 100%;
  height: 100%;
}

.Col2section1Contact {
  margin: 0px;
  padding: 0px;
  height: 100%;
  object-fit: cover;
  position: relative;
}


